<template>
  <div>
    <h2>
      <b>干货资讯</b>
    </h2>
    <div class="leftList">
      <div v-for="list in leftList" class="box" @click="$router.push({name:'NewsInfo', query:{id:list.id}})">
        <img :src="list.cover" alt="" class="img">
        <div class="right">
          <h4>{{ list.title }}</h4>
          <span class="info">{{ list.digest }}</span>
<!--                <ul class="bootom">-->
<!--                  <li>-->
<!--                    <span>作者：</span>{{ list.author }}-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <span>时间：</span>{{ list.pub_date }}-->
<!--                  </li>-->
<!--                </ul>-->
        </div>
      </div>
    </div>
    <!--page-->
    <div class="pull-center">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
  name: "newsList",
  data() {
    return {
      leftList: [],
      total: 0,
      page:1
      }
  },
  mounted() {
    this.getNewsList();
  },
  methods: {
    handleSizeChange(val) {
      console.log(val, 'val');
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getNewsList();
    },
    getNewsList() {
      let params = {
        url:null,
        page:this.page
      }
      this.$store.dispatch("getNews",params).then((res) => {
        this.leftList = res.data;
        this.total = res.total;
      });
    }
  }
}
</script>

<style lang="less" scoped>

</style>
